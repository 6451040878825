import React, { Component } from 'react'
import Select from 'react-select'
import AppAño from './AppAño'
import { colourOptions } from './data'

export default class AppMes extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectOptions : [
          {value:'1', label:'Enero'},
          {value:'2', label:'Febrero'},
          {value:'3', label:'Marzo'},
          {value:'4', label:'Abril'},
          {value:'5', label:'Mayo'},
          {value:'6', label:'Junio'},
          {value:'7', label:'Julio'},
          {value:'8', label:'Agosto'},
          {value:'9', label:'Septiembre'},
          {value:'10', label:'Octubre'},
          {value:'11', label:'Noviembre'},
          {value:'12', label:'Diciembre'},

      ],
      id: "",
      name: ''
    }
  }

  handleChange(e){
   
   this.setState({id:e.value, name:e.label})
  }

  render() {
    
    return (
      <div>
        <Select options={this.state.selectOptions}
         onChange={this.handleChange.bind(this)}
        />
    <p>Usted Seleccionó <strong>{this.state.name}</strong> </p>
     <AppAño
      combo1={this.props.combo1}
      combo2={this.props.combo2}
      combo3={this.state.name}
      comboId3={this.state.id}/>
     
      </div>
    )
  }
}
