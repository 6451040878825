import React, { Component } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Logout extends Component {       
 
    
    render() {

        fetch(`${process.env.REACT_APP_LOG}`, {
            credentials:'omit',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
                Usuario: cookies.get('Usuario'),
                Compania: cookies.get('Compania'),               
                Actividad: "Salio del Sistema"
                
            }),
            dataType: "jsonp",

            headers:{
              'Access-Control-Allow-Origin':'*','Accept': 'application/json',
              'Content-Type': 'application/json'
            }
           

        })  
        cookies.remove('Usuario', { path: "/" });
        cookies.remove('Copa', {path:'/'});
        cookies.remove('Compania', { path: "/" });
        cookies.remove('NombreCompania', {path:'/'});
        cookies.remove('Sucursal', { path: "/" });
        cookies.remove('Ceco', { path: "/" });
        cookies.remove('Almacen', { path: "/" });
        cookies.remove('Operacion', { path: "/" });
        cookies.remove('LogoCompania',{path:'/'});
        cookies.remove('Cono', {path:'/'});
        cookies.remove('Taco', {path:'/'});
        cookies.remove('Latente', {path:'/'});
        cookies.remove('Servidor', {path:'/'})


        return (

            <div>
                <script>
                    function Exit(params) {window.location.href = "/"}
                </script>
            </div>

        );
    }
}

export default Logout;