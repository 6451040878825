import React, { useState, Component } from 'react';
import { models, Embed, Report, IEmbedConfiguration, service, Page, global } from 'powerbi-client';
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react';
import 'powerbi-report-authoring';
import './Reporte.css'
import PrintIcon from '@material-ui/icons/Print';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';



function PowerRep(props) {
    var urll1 = props.Url;
    var acctok1 = props.Token;
    var idd1 = props.Id;
    var staticreport;


    function imprimir() {
        staticreport.print();
    }
    function PantallaCompleta() {

        staticreport.fullscreen();

    }

    return (
        <div>
            <PowerBIEmbed

                cssClassName={"report-style-class"}
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: idd1,
                    embedUrl: urll1,
                    accessToken: acctok1,

                    tokenType: models.TokenType.Aad,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        pageNavigation: {
                            visible: true
                        },

                        bars: {
                            actionBar: {
                                visible: false
                            }
                        },

                        background: models.BackgroundType.Transparent,

                    }
                }}
                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }]
                    ])
                }

                getEmbeddedComponent={(embeddedReport) => {
                    staticreport = embeddedReport;
                }}
            />
            <ListItem button>
                <Tooltip title="Imprimir">
                    <ListItemIcon>
                        <PrintIcon onClick={imprimir} />
                    </ListItemIcon>
                </Tooltip>
                <Tooltip title="Pantalla Completa">
                    <ListItemIcon>
                        < AspectRatioIcon onClick={PantallaCompleta} />
                 
                    </ListItemIcon>
                 
                </Tooltip>
           
            </ListItem>

       


        </div>
    );
}

export default PowerRep;
