import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Cookies from 'universal-cookie';
import Tarjeta2 from './Tarjeta2';
import LinearBuffer from './progress'



const cookies = new Cookies();



class DashboardsEfecto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuarios: [],
      isFetch: true,
    }
  }

  componentDidMount() {
       fetch(`${process.env.REACT_APP_DASH}=${cookies.get('Usuario')}&password=${atob(cookies.get('Copa'))}`)
      .then(res => res.json())
      .then(usuariosJson => this.setState({ usuarios: usuariosJson, isFetch: false }))
    
  }
  render() {

    if (this.state.isFetch) {
      return <LinearBuffer/>
    }

    let Tarjetas = this.state.usuarios.map(reporte => {
      return (
        <Col  xl='3' lg="4" md='6' sm="12" xs="12" >
          <Tarjeta2
            titulotarjeta={reporte.TituloTarjeta}
            titulo1={reporte.Titulo1}
            titulo2={reporte.Titulo2}
            logo={reporte.ImagenTarjeta}
            Id={reporte.ReportID}
            Url={reporte.EmbedURL}
            Token={reporte.EmbedToken}
          />
        
          </Col>
       
      )
    })
    return (
      
      <Row>
   {Tarjetas}      
      </Row>
      
    )
  }
}

export default DashboardsEfecto;