import React, { Component } from 'react';
import './Login.css';
import Cookies from 'universal-cookie';


const cookies = new Cookies();
class Inicio extends Component { 
   
    componentDidMount() {
        if (!cookies.get('Usuario')) {
            window.location.href = "/";
        }
        
    }
    
    render() {
        return (
            <div>                               
               <div className="containerPrincipal" >                  
                    <div className="containerSecundario">
                        <img className="text-center img-fluid z-depth-0" src={cookies.get('LogoCompania')} alt="" />
                    </div>       
                </div>              
            </div>
        );
    }
}

export default Inicio;