import React, { Component } from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Cookies from 'universal-cookie';
import LockIcon from '@material-ui/icons/Lock';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import 'animate.css/animate.compat.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Popupmailclass from './components/popupmailclass';
import ErrorIcon from '@material-ui/icons/Error';
import './LoginPrincipal.scss';


const initialValues = {
    login: '',
    password: '',
    open: true
}

const onSubmit = values => {
}

const validationSchema = Yup.object({
    name: Yup.string().required('Name Requerido'),
    login: Yup.string().email('E-mail Formato Invalido!').required('E-mail Requerido!'),
    password: Yup.string().required(' Password Requerido!')
})

const cookies = new Cookies();

function LoginPrincipal() {
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema

    })

    if (formik.values.login && formik.values.password) {
        initialValues.open = false
    }
    const iniciarSesion = async () => {
        store.addNotification({
            title: " PROCESANDO !",
            message: "Espere un Momento!",
            type: "info",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", " fadeIn"],
            animationOut: ["animated", "fadeOut"],

            dismiss: {
                duration: 20000,
                onScreen: true
            }
        });


        await axios.get(process.env.REACT_APP_LOGIN, { params: { login: formik.values.login, password: formik.values.password } })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.length > 0) {
                    var respuesta = response[0];

                    cookies.set('Usuario', respuesta.Usuario, { path: "/" });
                    cookies.set('Copa', btoa(respuesta.Password), { path: "/" });
                    cookies.set('Compania', respuesta.Compania, { path: "/" });
                    cookies.set('NombreCompania', respuesta.NombreCompania, { path: "/" });
                    cookies.set('Operacion', respuesta.Operacion, { path: "/" });
                    cookies.set('LogoCompania', respuesta.LogoCompania, { path: "/" });
                    cookies.set('Cono', btoa(respuesta.NombreSoporte), { path: "/" });
                    cookies.set('Taco', btoa(respuesta.CorreoSoporte), { path: "/" });
                    cookies.set('Latente', btoa(respuesta.TelefonoSoporte), { path: "/" });
                    cookies.set('Servidor', respuesta.Servidor, { path: "/" });

                    //************************************************************************************** */
                    fetch(`${process.env.REACT_APP_LOG}`, {
                        credentials: 'omit',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({

                            Usuario: cookies.get('Usuario'),
                            Compania: cookies.get('Compania'),
                            Actividad: "Ingresó al Sistema"

                        }),
                        dataType: "jsonp",

                        headers: {
                            'Access-Control-Allow-Origin': '*', 'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }


                    })

                    //******************************************************************************************** */
                    setTimeout(salir, 1000);
                    store.addNotification({
                        title: "Bienvenido !",
                        message: "Has Ingresado Exitosamente al Sistema !!",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }

                    });

                    function salir() {
                        window.location.href = "/Inicio"
                    }
                } else {
                    store.addNotification({
                        title: "ERROR !!",
                        message: "Usuario o Contraseña está Incorrecta !!",
                        type: "warning",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                }
            })
            .catch(error => {

                store.addNotification({
                    title: "ERROR !!",
                    message: "Usuario o Contraseña está Incorrecta !!",
                    type: "warning",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],

                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
            })
    }

    if (cookies.get('Usuario')) {
        window.location.href = "/Inicio";
    }



    return (

        <div className="container">            
            <ReactNotification />
            <div id="login" className="signin-card">
                <div className="logo-image">
                    <img className="App-logo" src="LogoDashboards6.png" alt="Logo" title="Logo" width="250" />
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                {/*  <AccountCircle /> */}
                                <img src='usuario1.png' width='30' />
                            </Grid>
                            <Grid item >
                                <TextField
                                    id="input-with-icon-grid"
                                    label="Usuario"
                                    name="login"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.login}
                                    style={{ width: "250px" }}
                                />
                                {formik.touched.login && formik.errors.login ?
                                    (<div className="error"><img src='warning3.png' width='20' />  {formik.errors.login}</div>) : null}
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                                {/* <VisibilityOff /> */}
                                <img src='contrasena.png' width='30' />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="input-with-icon-grid"
                                    label="Password"
                                    type="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    style={{ width: "250px" }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password} />

                                {formik.touched.password && formik.errors.password ?
                                    (<div className="error"><img src='warning3.png' width='20' /> {formik.errors.password}</div>) : null}
                            </Grid>
                        </Grid>
                        <br />
                        <div align="center">
                            <button className="btn btn-primary" disabled={initialValues.open}
                                onClick={iniciarSesion} style={{ width: "150px" }}
                                color="Primary" type="submit">
                                <img src='login.png' width='20'/>&nbsp;&nbsp;&nbsp;<strong><a>Login</a></strong> </button>
                            <br />
                            <br />
                            {/*           <button className="btn btn-link" onClick={() => this.iniciarMail()} style={{ width: "250px" }} color=""> <strong>¿Olvidaste tu Contraseña?</strong> </button> */}
                            <Popupmailclass />
                        </div>
                    </div>
                </form>
            </div>
        </div>



    );
}

export default LoginPrincipal;