import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MailIcon from '@material-ui/icons/Mail';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import 'animate.css/animate.compat.css';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Popupmailclass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            form: {
                Login: '',
                Correo: "Si"
            }
        };


    }

    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };


    handleChange = async e => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });

    }
    procesando() {
        store.addNotification({
            title: " Procesando.",
            message: " NOTA: Visualizar el mail en la bandeja de Correo no deseado.",

            type: "info",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", " fadeIn"],
            animationOut: ["animated", "fadeOut"],

            dismiss: {
                duration: 13000,
                onScreen: true
            }
        });
    }

    iniciarSesion = async () => {

        await axios.get(process.env.REACT_APP_MAIL, { params: { Login: this.state.form.login, Correo: this.state.form.Correo } })

            .then(response => {

                return response.data;
            })
            .then(response => {

                if (response.length > 0) {
             
                    setTimeout(salir, 1000);

                    store.addNotification({
                        title: "Password!",
                        message: "Se envió exitosamente su contraseña al mail",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }
                    });

                    function salir() {
                        window.location.href = "/"
                    }

                } else {
                    store.addNotification({
                        title: "Error.",
                        message: "El Mail que ingresó es incorrecto.",
                        type: "warning",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],

                        dismiss: {
                            duration: 2000,
                            showIcon: true,
                            onScreen: true
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                <Button  color="primary" onClick={() => this.handleClickOpen()}>
                    ¿Olvidaste tu Contraseña?
                </Button>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#EDEFED" }}>
                        <img className="text-center img-fluid z-depth-0" src="logodashboards12.png" alt="" label="Hola" width="100" />
                            Restablecimiento de contraseña.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <br />
                            <body2> Ingresa tu correo electrónico para buscar tu cuenta.</body2>
                            <br /> <br />
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    {/*<MailIcon /> */}
                                    <img src='e-mail.png' width='25' />
                                </Grid>
                                <Grid item>
                                    <TextField id="input-with-icon-grid" label="Correo Electrónico" name="login" onChange={this.handleChange} style={{ width: "350px" }} />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.iniciarSesion(); this.procesando(); }} 
                        variant="contained"
                        color="primary">
                            Enviar
                        </Button>
                        <Button onClick={() => this.handleClose()} 
                        variant="contained"
                        color="default">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Popupmailclass;