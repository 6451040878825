import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'universal-cookie';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import Axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';

const cookies = new Cookies();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class PopUpDescargar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,         
        };       

    }
    
    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };

    download (){
        Axios({
         url:`${process.env.REACT_APP_DESCARGAR}=${cookies.get('Compania')}`,
        
          method:'GET',
          responseType:'blob'
        })
        .then((response) =>{        
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${cookies.get('Compania')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          
        })
      
      }    

    render() {      
        return (
            <div>
              {/* <CloudDownloadIcon style={{ color:'#a54400' }} onClick={() => this.handleClickOpen()}/> */}             
                <Tooltip title='Descargar Archivo'>
                <img src='bajar.png' width='25' onClick={() => this.handleClickOpen()}/>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#EDEFED" }}>
                     {/*  <DescriptionIcon fontSize="large"/> */} 
                       <img src='descargar.png' width='40'/>
                       &nbsp; &nbsp; Descargar Archivo Maestro.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <br />
                            <body2> NOTA. El archivo anexo no se debe de cambiar el nombre, únicamente se debe de conservar el mismo nombre del archivo y los mismos campo.</body2>
                            <br /> <br />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.20.0/axios.min.js" integrity="sha512-quHCp3WbBNkwLfYUMd+KwBAgpVukJu5MncuQaWXgCrfgcxCJAq/fo+oqrRKOj+UKEmyMCG3tb8RB63W+EmrOBg==" crossorigin="anonymous"></script>
          
                        <Button onClick={() => {this.download(); this.handleClose()}} 
                        variant="contained"
                        color="primary">
                            Descargar
                        </Button>
                        <Button onClick={() => this.handleClose()} 
                        variant="contained"
                        color="default">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default PopUpDescargar;