import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'universal-cookie';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const cookies = new Cookies();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class PopUpContacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,         
        };       

    }
    
    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };

      

    render() {      
        return (
            <div>
              {/* <ContactSupportRoundedIcon style={{ color:'#a54400' }} onClick={() => this.handleClickOpen()}/> */}             
              <img src='support.png' width='25' onClick={() => this.handleClickOpen()}/>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#EDEFED" }}>
                     {/*  <ContactSupportRoundedIcon fontSize="large"/> */} 
                       <img src='mexico1.png' width='60'/>
                       &nbsp;&nbsp; Soporte Técnico.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        <br /> 
                            {/*<body2> <AccountBoxRoundedIcon style={{ color:'#a54400' }}/>&nbsp;&nbsp;{cookies.get('NombreSoporte')}.</body2> */}
                            <body2>  <img src='contacto.png' width='35'/> &nbsp;&nbsp;{atob(cookies.get('Cono'))}.</body2>
                            <br /> 
                            <br /> 
                           {/* <body2> <ContactMailRoundedIcon style={{ color:'#a54400' }}/>&nbsp;&nbsp;{cookies.get('CorreoSoporte')}.</body2> */}
                            <body2>  <img src='email.png' width='40'/> &nbsp;&nbsp;{atob(cookies.get('Taco'))}.</body2>
                            <br />
                            <br /> 
                           {/* <body2> <ContactPhoneRoundedIcon style={{ color:'#a54400' }}/>&nbsp;&nbsp;{cookies.get('TelefonoSoporte')}.</body2> */}
                           <body2>  <img src='phone.png' width='40'/> &nbsp;&nbsp;{atob(cookies.get('Latente'))}.</body2> 
                            <br />  <br /> 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.20.0/axios.min.js" integrity="sha512-quHCp3WbBNkwLfYUMd+KwBAgpVukJu5MncuQaWXgCrfgcxCJAq/fo+oqrRKOj+UKEmyMCG3tb8RB63W+EmrOBg==" crossorigin="anonymous"></script>
          
                     
                       
                         <CloseRoundedIcon onClick={() => this.handleClose()} color="primary" fontSize="large"/>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default PopUpContacto;