import React, { Component } from 'react';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';


const cookies = new Cookies();

class ButtonDelete extends Component {
    constructor(props) {
        super(props);

        this.state={
            habilitado:true,

            tabla:this.props.combo1,
            division:this.props.combo2,
            mes:this.props.combo3,
            año:this.props.comboId4
        }

     
           
    }
    
          Alerta=()=>{
            
              swal({
                    title:'Borrar',
                   text: `
                   Esta seguro que desea borrar los siguientes datos?   
                   Tabla = ${this.props.combo1} 
                   Division = ${this.props.combo2}
                   Mes = ${this.props.combo3}
                   Año = ${this.props.comboId4}`,
                   icon:'warning',
                   buttons:['No','Si']
                })
                  .then(respuesta=>{
                       if(respuesta){
                           
                        fetch(`${process.env.REACT_APP_LOG}`, {
                            credentials:'omit',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                           
                                Usuario: cookies.get('Usuario'),
                                Compania: cookies.get('Compania'),               
                                Actividad: ` Borró los siguientes datos...   
                                Tabla = ${this.props.combo1} 
                                Division = ${this.props.combo2}
                                Mes = ${this.props.combo3}
                                Año = ${this.props.comboId4}`
                                
                            }),
                            dataType: "jsonp",
                    
                            headers:{
                              'Access-Control-Allow-Origin':'*','Accept': 'application/json',
                              'Content-Type': 'application/json'
                            }
                           
                    
                         }) 
                           swal({
                              text: 'Los Archivos se han Borrado con Exito',
                              icon:'success'
                              
                            
                            })


                            fetch(`${process.env.REACT_APP_BORRAR}=${cookies.get('Servidor')}&Tabla=${this.props.combo1}&Division=${this.props.combo2}&mes=${this.props.comboId3}&anio=${this.props.comboId4}`, {
                                credentials:'omit',
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })  

                            setTimeout(salir,500);

                            function salir() {
                              
                                window.location.href = "/Inicio"
                            }
                            
                        }
                       

                    })
                   
            }



   
   cancel = ()=> {
                              
    window.location.href = "/Inicio"
}

    render() {

         if(this.props.comboId3 && this.props.comboId4 && this.props.combo2){
             this.state.habilitado=false
         }

        return (
            <div>

                <Button onClick={() => this.Alerta()}
                    variant="contained"
                    color="primary"
                    type='submit'
                    disabled={this.state.habilitado}>
                    Borrar 
                        </Button>&nbsp;&nbsp;

                        <Button onClick={() => this.cancel()}
                    variant="contained"
                    color="default"
                    type='submit'
                    >
                    Cancelar
                        </Button>

            </div>
        );
    }
}

export default ButtonDelete;