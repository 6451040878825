import React, { Component } from 'react';
import './Tarjeta2.scss';
import FullPopUp from './FullPopUp';

class Tarjeta2 extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                <div className="skill-card">
                    <header className="skill-card__header">
                        <img className="skill-card__icon"  src={this.props.logo} alt="HTML5 Logo" />
                    </header>
                    <section class="skill-card__body">
                        <h2 className="skill-card__title">{this.props.titulotarjeta}</h2>
                        <span className="skill-card__duration">Dashboard</span>
                        <ul className="skill-card__knowledge">
                            <FullPopUp
                                titulo={this.props.titulotarjeta}
                                Id={this.props.Id}
                                Url={this.props.Url}
                                Token={this.props.Token}
                            />
                        </ul>
                    </section>
                </div>
                <br />
            </>
        );
    }
}

export default Tarjeta2;