import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AppDivision from './AppDivision';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { colourOptions } from './data';

const cookies = new Cookies();

export default class AppTabla extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectOptions : [],
      id: "",
      name: '',
      habilitado:'',
      value:''
     
      
    }
  }

 async getOptions(){
    const res = await axios.get( `${process.env.REACT_APP_TABLAS}=${cookies.get('Compania')}`)
    const data = res.data

    const options = data.map(d => ({
      "value" : d.Tabla,
      "label" : d.Tabla
     

    }))

    this.setState({selectOptions: options})

    

  
  }

 

  handleChange(e){
    
   this.setState({id:e.value, name:e.label})
   
   if(!(this.state.selectOptions[0].value='')){
     this.setState({habilitado:true})
   }
   console.log('IdCambiado', this.state.habilitado)
  }

  componentDidMount(){
      this.getOptions()
  }

  render() {
   
    return (
      <div>
        <Select options={this.state.selectOptions}
         onChange={this.handleChange.bind(this)}/>
        <p>Usted Seleccionó <strong>{this.state.name}</strong></p>
        <AppDivision 
         combo1={this.state.name}/>
    
      </div>
    )
  }
}
