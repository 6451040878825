import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Cookies from 'universal-cookie';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import 'animate.css/animate.compat.css';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';


const cookies = new Cookies();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
class PopUpCambiarContraseña extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            form: {
                Login: cookies.get('Usuario'),
                Compania: cookies.get('Compania'),
                PasswordNuevo: '',
                PasswordConfirma: '',
                Password: ''
            }        
        };       

    }
    
    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };

  

    handleChange = async e => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });


    }


    metodoPUT() {
        if (this.state.form.PasswordNuevo == this.state.form.PasswordConfirma && this.state.form.PasswordConfirma != "") {
            fetch(`${process.env.REACT_APP_RECUPERA}`, {
                credentials: 'omit',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    usuario: this.state.form.Login,
                    password: this.state.form.PasswordConfirma,

                

                }),
                dataType: "jsonp",

                headers: {
                    'Access-Control-Allow-Origin': '*', 'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

                .then(response => response.json())
                .catch(error => console.error('Error:', error))
               

                   
        fetch(`${process.env.REACT_APP_LOG}`, {
            credentials:'omit',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
                Usuario: cookies.get('Usuario'),
                Compania: cookies.get('Compania'),               
                Actividad: "Cambio Password"
                
            }),
            dataType: "jsonp",

            headers:{
              'Access-Control-Allow-Origin':'*','Accept': 'application/json',
              'Content-Type': 'application/json'
            }
           

          })         

            setTimeout(salir, 1000);


            store.addNotification({
                title: "Password!",
                message: "Has Cambiado Exitosamente tu Contraseña!!",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],

                dismiss: {
                    duration: 2000,
                    onScreen: true
                }

            });

            function salir() {
                window.location.href = "/Inicio"
            }
    } else {
            store.addNotification({
                title: "ERROR!!",
                message: "Una de las Contraseña es Incorrecta",
                type: "warning",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],

                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
        }
    }

    cancelar() {
        window.location.href = "/Inicio";
    }    

     render() {      
        return (
            <div>
             {/*  <LockIcon  onClick={() => this.handleClickOpen()} style={{ color:'#a54400' }}/> */}
               <Tooltip title='Cambiar Contraseña'>
               <img src='password1.png' width='25' onClick={() => this.handleClickOpen()}/>             
               </Tooltip>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" >
                       
                       <img className="text-center img-fluid z-depth-0" src={cookies.get('LogoCompania')} alt="" width="100" height="100" />
                        <a><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cambiar Contraseña</strong></a>
                    
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                        <Box fontStyle="oblique" textAlign="center">Usuario:<h6 style={{ color: "#08088A" }}>{cookies.get('Usuario')}</h6></Box>
                        <FormGroup>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <img src='contraseña1.png' width='30'/>
                                </Grid>
                                <Grid item>
                                    <TextField id="input-with-icon-grid" label="Nueva Contraseña" type="password" name="PasswordNuevo" onChange={this.handleChange} style={{ width: "300px" }} />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                   {/* <VisibilityOff /> */}
                                   <img src='contraseña1.png' width='30'/>
                                </Grid>
                                <Grid item>
                                    <TextField id="input-with-icon-grid" label="Confirmar Contraseña" type="password" name="PasswordConfirma" onChange={this.handleChange} style={{ width: "300px" }} />
                                </Grid>
                            </Grid>
                        </FormGroup>
                         
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.20.0/axios.min.js" integrity="sha512-quHCp3WbBNkwLfYUMd+KwBAgpVukJu5MncuQaWXgCrfgcxCJAq/fo+oqrRKOj+UKEmyMCG3tb8RB63W+EmrOBg==" crossorigin="anonymous"></script>
         
                        <Button onClick={() => this.metodoPUT()}
                        variant="contained"
                        color="primary">
                            Aceptar
                        </Button>
                        <Button onClick={() => this.handleClose()} 
                        variant="contained"
                        color="default">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default PopUpCambiarContraseña;