import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import Cookies from 'universal-cookie';
import Box from '@material-ui/core/Box';
import AppMes from './AppMes';
import Tooltip from '@material-ui/core/Tooltip';

const cookies = new Cookies();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
class PopUpActualizar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, 
                  
        };       

    }
    
    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };

       
     render() {      
        return (
            <div>
             {/*  <LockIcon  onClick={() => this.handleClickOpen()} style={{ color:'#a54400' }}/> */}
               <Tooltip title='Actualizar Datos'>
               <img src='actualizar2.png' width='25' onClick={() => this.handleClickOpen()}/>             
               </Tooltip>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" >
                       
                       <img className="text-center img-fluid z-depth-0" src={cookies.get('LogoCompania')} alt="" width="100" height="100" />
                        <a><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actualizar Datos.&nbsp;&nbsp;&nbsp;</strong></a>
                    
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        <Box fontStyle="oblique" textAlign="center">Usuario:<h6 style={{ color: "#08088A" }}>{cookies.get('Usuario')}</h6></Box>
                       
                           <AppMes/>
                        
                         
                        </DialogContentText>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}

export default PopUpActualizar;