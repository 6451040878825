import React, { Component } from 'react'
import Select from 'react-select'
import ButtonDelete from './ButtonDelete'
import { colourOptions } from './data'

export default class AppAño extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectOptions : [
          {value:'2015', label:'Año 2015'},
          {value:'2016', label:'Año 2016'},
          {value:'2017', label:'Año 2017'},
          {value:'2018', label:'Año 2018'},
          {value:'2019', label:'Año 2019'},
          {value:'2020', label:'Año 2020'},
          {value:'2021', label:'Año 2021'},
          {value:'2022', label:'Año 2022'},
          {value:'2023', label:'Año 2023'},
          {value:'2024', label:'Año 2024'},
          {value:'2025', label:'Año 2025'},
          {value:'2026', label:'Año 2026'},
          {value:'2027', label:'Año 2027'},
          {value:'2028', label:'Año 2028'},
          {value:'2029', label:'Año 2029'},
          {value:'2030', label:'Año 2030'},
          {value:'2031', label:'Año 2031'},

      ],
      id: "",
      name: ''
    }
  }

  handleChange(e){
   
   this.setState({id:e.value, name:e.label})
  } 

  render() {
    
    return (
      <div>
        <Select options={this.state.selectOptions} 
        onChange={this.handleChange.bind(this)}
      />
    <p>Usted Seleccionó <strong>{this.state.name}</strong> </p>
     <ButtonDelete
     combo1={this.props.combo1}
     combo2={this.props.combo2}
     combo3={this.props.combo3}
     comboId3={this.props.comboId3}
     combo4={this.state.name}
     comboId4={this.state.id}
     />
     
      </div>
    )
  }
}