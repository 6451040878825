import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import AppMes from './AppMes'
import { colourOptions } from './data'

import Cookies from 'universal-cookie';


const cookies = new Cookies();

export default class AppDivision extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectOptions : [],
      id: "",
      name: '',
     
      
    }
  }

  handleChange(e){
 
   this.setState({id:e.value, name:e.label})
  }

 async getOptions(){
 
    const res = await axios.get(`${process.env.REACT_APP_DIVISION}=${cookies.get('Servidor')}&tabla=${this.props.combo1}`)
    const data = res.data
    const options = data.map(d => ({
      "value" :d.Division,
      "label" :d.Division 
    }))
    this.setState({selectOptions: options})
   
 } 


componentDidUpdate(prevProps, combo1){  
 
  if(prevProps.combo1 == this.props.combo1){
  
     
  }else{
    this.getOptions()
  }
}

  render() {
  
   
    return (
      <div>
        <Select options={this.state.selectOptions}
         onChange={this.handleChange.bind(this)}/>
        <p>Usted Seleccionó<strong>{this.state.name} {this.state.contador}</strong></p>
               
        <AppMes
         combo1={this.props.combo1}
         combo2={this.state.name}/>
      </div>
    )
  }
}
