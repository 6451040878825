import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Divider from '@material-ui/core/Divider';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import 'animate.css/animate.compat.css';
import DescriptionIcon from '@material-ui/icons/Description';
import './PopUpCargar.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';


const cookies = new Cookies();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class PopUpCargar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            selectedFile: null        
        };       

    }
    
    handleClickOpen = async e => {
        await this.setState({ open: true })

    };

    handleClose = async e => {
        await this.setState({ open: false })

    };

    
    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    onFileUpload = () => {
        const formData = new FormData();
        if (this.state.selectedFile == null) {
            return (<div>
                <h5>Selecciona un Archivo antes de dar click al boton de cargar</h5>
            </div>)
        }

        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

       

        axios.post(`${process.env.REACT_APP_CARGAR}=${cookies.get('Servidor')}&`, formData);

        fetch(`${process.env.REACT_APP_LOG}`, {
            credentials:'omit',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           
                Usuario: cookies.get('Usuario'),
                Compania: cookies.get('Compania'),               
                Actividad: "Subió Archivo"
                
            }),
            dataType: "jsonp",

            headers:{
              'Access-Control-Allow-Origin':'*','Accept': 'application/json',
              'Content-Type': 'application/json'
            }
           

          })         

            setTimeout(salir, 2000);


            store.addNotification({
                title: "Subiendo Archivo!",
                message: "Espere un momento, cargando información a la Base de Datos",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],

                dismiss: {
                    duration: 2000,
                    onScreen: true
                }

            });

            function salir() {
                window.location.href = "/Inicio"
            }

    };

    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <h4>Detalles del Archivo:</h4>
                    <p>Nombre del Archivo: {this.state.selectedFile.name}</p>
                    <p>Tipo de Archivo: {this.state.selectedFile.type}</p>
                    <p>
                        Ultima Modificación:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h5>Selecciona un Archivo antes de dar click al boton de cargar</h5>
                </div>
            );
        }
    };

      

    render() {      
        return (
            <div>
               {/*<CloudUploadIcon style={{ color:'#a54400' }} onClick={() => this.handleClickOpen()}/>*/}              
               <Tooltip title='Cargar Archivo'>
                <img src='subir.png' width='25' onClick={() => this.handleClickOpen()}/>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#EDEFED" }}>
                 
                     {/*  <DescriptionIcon fontSize="large"/> */}
                       <img src='cargararchivo.png' width='40'/>
                       &nbsp; &nbsp; &nbsp; &nbsp;Cargar Archivo Maestro.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                  
                        <input type="file" onChange={this.onFileChange} id="contained-button-file" className="cargararchivo"/>
                      
                        <label htmlFor="contained-button-file">
                         <Button variant="contained" color="primary" component="span" onChange={this.onFileChange}>
                         <CloudUploadIcon/>&nbsp; Seleccionar Archivo
                         </Button>
                          </label>
                          <br /> 
                    
                        <Divider/>
                            <body2> {this.fileData()}</body2>
                           
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.20.0/axios.min.js" integrity="sha512-quHCp3WbBNkwLfYUMd+KwBAgpVukJu5MncuQaWXgCrfgcxCJAq/fo+oqrRKOj+UKEmyMCG3tb8RB63W+EmrOBg==" crossorigin="anonymous"></script>
                       
                        <Button onClick={() => this.onFileUpload()} 
                        variant="contained"
                        color="primary">
                            Cargar
                        </Button>
                        <Button onClick={() => this.handleClose()} 
                        variant="contained"
                        color="default">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default PopUpCargar;